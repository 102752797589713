body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  background-color: #faf8ef;
  width: 100vw;
  height: 100vh;
}

#root {
  width: 100vw;
  height: 100vh;
}