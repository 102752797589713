main {
  --min-game-size: 300px;
  --max-game-size: 600px;
  --preferred-game-size: 25vw;
  --field-border-radius: clamp(2px, 0.5vw, 8px);
  --grid-gap: clamp(7px, 0.75vw, 12px);

  position: relative;
  width: 100vw;
  height: 100vh;  
  display: flex;
  align-items: center;
  justify-content: center;
}

#game {
  align-self: center;
  justify-self: center;
  position: relative;
  display: grid;
  width: clamp(var(--min-game-size), var(--preferred-game-size), var(--max-game-size));
  height: clamp(var(--min-game-size), var(--preferred-game-size), var(--max-game-size));
  gap: var(--grid-gap);
  grid-template-rows: repeat(4, 1fr);
  border-radius: calc(var(--field-border-radius) + var(--grid-gap) / 2);
  background-color: #bbada0;
  padding: var(--grid-gap);
}

.row {
  display: grid;
  gap: var(--grid-gap);
  grid-template-columns: repeat(4, 1fr);
}

.field {
  position: relative;
  color: #776e65;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--field-border-radius);
  font-size: clamp(25px, 2.5vw, 48px);
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 700;
  overflow: hidden;
  transition: background-color 0.05s ease-in-out;
}

main button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  font-size: 18px;
  background-color: #bbada0;
  color: #f9f6f2;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}